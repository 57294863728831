import * as React from "react"

import Layout from "components/layout"
import Seo from "components/seo"
import { Flex, Heading } from "theme-ui"
import { SectionHeader, SectionParagraph } from "components/various"
import { StaticImage } from "gatsby-plugin-image"
import TextLoop from "components/TextLoop"

const quotes = [
  "Many bands make light work",
  "It's never too late to do the right thing",
  "Practice makes perfect",
  "Do the best you can until you know better. Then when you know better, do better",
  "The only thing standing between you and outrageous success is continuous progress",
  "The greater the difficulty, the more the glory in surmounting it",
]

const ClientsPage = () => (
  <Layout>
    <Seo title="References" />
    <StaticImage
      src="../images/business-feedback-results-review-survey-concept.jpg"
      loading="eager"
      quality={95}
      formats={["auto", "webp", "avif"]}
      sx={{ width: "full", height: [200, 300] }}
    />
    <Flex
      sx={{
        flexDirection: "column",
        alignItems: "center",
        my: 2,
        p: 3,
        px: [2, 3, 3, 6, 7],
        justifyContent: "space-around",
      }}
    >
      <SectionHeader>Nos principales Références</SectionHeader>
      <SectionParagraph>
        Notre équipe a réalisé de nombreuses missions d'audit, d'organisation de
        Conseil, de mise à niveau et d'évaluation d'entreprises en Tunisie et
        dans d'autres pays que ce soit à travers les grandes structures par
        lesquelles ont passé les associés durant leur cursus professionnel (EY
        Tunisie & Générale d'expertise et de management GEM membre du réseau
        UHY, Audit & Consulting Business, …) ou par la participation directe de
        Advisory & Certifying in Busines « ACB » dans des missions d'apurement,
        d'audit, d'organisation et de conseil.
      </SectionParagraph>
      <Heading
        as={"h2"}
        sx={{
          my: 4,
          position: "relative",
          ":after": {
            variant: "after.middle",
          },
          textAlign: "center",
        }}
      >
        En matiere d'audit
      </Heading>
      <Heading as={"h3"} sx={{ color: "primary", my: 4, textAlign: "center" }}>
        Concessionnaires
      </Heading>
      <StaticImage
        src="../images/auto.png"
        loading="eager"
        placeholder="blurred"
        quality={95}
        formats={["auto", "webp", "avif"]}
        sx={{ maxWidth: 700 }}
      />
      <Heading as={"h3"} sx={{ color: "primary", my: 4, textAlign: "center" }}>
        Pétrole & Energie
      </Heading>
      <StaticImage
        src="../images/petrol.png"
        loading="eager"
        placeholder="blurred"
        quality={95}
        formats={["auto", "webp", "avif"]}
        sx={{ maxWidth: 700 }}
      />
      <Heading as={"h3"} sx={{ color: "primary", my: 4, textAlign: "center" }}>
        Agriculture
      </Heading>
      <StaticImage
        src="../images/agriculture.png"
        loading="eager"
        placeholder="blurred"
        quality={95}
        formats={["auto", "webp", "avif"]}
        sx={{ maxWidth: 700 }}
      />
      <Heading as={"h3"} sx={{ color: "primary", my: 4, textAlign: "center" }}>
        Industrie & Autres Secteurs de Production et de Distribution
      </Heading>
      <StaticImage
        src="../images/industry.png"
        loading="eager"
        placeholder="blurred"
        quality={95}
        formats={["auto", "webp", "avif"]}
        sx={{ maxWidth: 700 }}
      />
      <Heading as={"h3"} sx={{ color: "primary", my: 4, textAlign: "center" }}>
        Transport et autres services
      </Heading>
      <StaticImage
        src="../images/transport.png"
        loading="eager"
        placeholder="blurred"
        quality={95}
        formats={["auto", "webp", "avif"]}
        sx={{ maxWidth: 700 }}
      />
      <Heading as={"h3"} sx={{ color: "primary", my: 4, textAlign: "center" }}>
        Tourisme
      </Heading>
      <StaticImage
        src="../images/tourism.png"
        loading="eager"
        placeholder="blurred"
        quality={95}
        formats={["auto", "webp", "avif"]}
        sx={{ maxWidth: 700 }}
      />
      <Heading as={"h3"} sx={{ color: "primary", my: 4, textAlign: "center" }}>
        Santé
      </Heading>
      <StaticImage
        src="../images/health.png"
        loading="eager"
        placeholder="blurred"
        quality={95}
        formats={["auto", "webp", "avif"]}
        sx={{ maxWidth: 210 }}
      />
      <Heading as={"h3"} sx={{ color: "primary", my: 4, textAlign: "center" }}>
        Banques, Assurances & Finances
      </Heading>
      <StaticImage
        src="../images/banks.png"
        loading="eager"
        placeholder="blurred"
        quality={95}
        formats={["auto", "webp", "avif"]}
        sx={{ maxWidth: 700 }}
      />
      <Heading as={"h3"} sx={{ color: "primary", my: 4, textAlign: "center" }}>
        Télécommunications
      </Heading>
      <StaticImage
        src="../images/telecoms.png"
        loading="eager"
        placeholder="blurred"
        quality={95}
        formats={["auto", "webp", "avif"]}
        sx={{ maxWidth: 700 }}
      />
      <Heading as={"h3"} sx={{ color: "primary", my: 4, textAlign: "center" }}>
        Travaux publics & Promotion immobilière
      </Heading>
      <StaticImage
        src="../images/public.png"
        loading="eager"
        placeholder="blurred"
        quality={95}
        formats={["auto", "webp", "avif"]}
        sx={{ maxWidth: 700 }}
      />
      <Heading
        as={"h2"}
        sx={{
          my: 4,
          textAlign: "center",
          position: "relative",
          ":after": {
            variant: "after.middle",
          },
        }}
      >
        En matiere d'audit des couts petroliers, de conseil et d'assistance
        comptable et fiscale
      </Heading>
      <StaticImage
        src="../images/costs.png"
        loading="eager"
        placeholder="blurred"
        quality={95}
        formats={["auto", "webp", "avif"]}
        sx={{ maxWidth: 700 }}
      />
      <Heading
        as={"h2"}
        sx={{
          my: 4,
          position: "relative",
          ":after": {
            variant: "after.middle",
          },
          textAlign: "center",
        }}
      >
        Missions internationales
      </Heading>
      <StaticImage
        src="../images/international.png"
        loading="eager"
        placeholder="blurred"
        quality={95}
        formats={["auto", "webp", "avif"]}
        sx={{ maxWidth: 700 }}
      />
    </Flex>
    <TextLoop texts={quotes} />
  </Layout>
)

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="References" />

export default ClientsPage
